<script setup lang="ts">
import { useUserSession } from "addeus-common-library/stores/userSession";
import { watch } from "vue";
import type { LocationAsRelativeRaw } from "vue-router";
import { useRouter } from "vue-router";
import extendModels from "../extend-models";
import { usePermissionStore } from "../stores/usePermissionStore";

const userSession = useUserSession();

const router = useRouter();
extendModels();

const permissionStore = usePermissionStore();
void permissionStore.handlePermissionsFromQuery();

function getFirstAllowedRoute(): LocationAsRelativeRaw {
    const route = router.getRoutes().find((route) => {
        return (
            route.meta !== undefined &&
            route.meta.permissions !== undefined &&
            userSession.hasPermission(route.meta.roles)
        );
    });
    if (!route)
        return {
            name: "dashboard",
        };
    return route;
}
watch(
    () => userSession.user,
    () => {
        if (
            router.currentRoute.value.meta !== undefined &&
            (router.currentRoute.value.meta.roles !== undefined ||
                router.currentRoute.value.meta.requiresAuth === true) &&
            userSession.user === null
        ) {
            void router.push({
                path: "/auth/login",
            });
        } else if (
            router.currentRoute.value.meta !== undefined &&
            router.currentRoute.value.meta.roles !== undefined &&
            userSession.hasPermission(router.currentRoute.value.meta.roles) === false
        ) {
            void router.push(getFirstAllowedRoute());
        }
    },
);
</script>
<template>
    <RouterView v-slot="{ Component }">
        <Transition name="translate-page-x" mode="out-in">
            <component :is="Component" />
        </Transition>
    </RouterView>
</template>
