// stores/usePermission.js
import { createGlobalState } from "@vueuse/core";
import { useRouter } from "vue-router";

export const usePermissionStore = createGlobalState(() => ({
    permissions: {},

    handlePermissionsFromQuery: () => {
        const router = useRouter();
        const query = router.currentRoute.value.query;

        const permissionKeys = Object.keys(query).filter((key) =>
            key.startsWith("add-permission-"),
        );

        permissionKeys.forEach((key) => {
            const socialNetwork = key.split("add-permission-")[1];
            const permissionValue = query[key];

            localStorage.setItem(
                `add-permission-${socialNetwork}`,
                JSON.stringify(permissionValue),
            );
        });

        const { query: currentQuery, ...routeWithoutQuery } = router.currentRoute.value;
        const updatedQuery = { ...currentQuery };

        permissionKeys.forEach((key) => {
            delete updatedQuery[key];
        });

        void router.replace({
            ...routeWithoutQuery,
            query: updatedQuery,
        });

        return Promise.resolve();
    },
    getPermissionFromStorage: function (socialNetwork: string) {
        const permissionKey = `add-permission-${socialNetwork}`;
        const permissionData = localStorage.getItem(permissionKey);

        if (permissionData) {
            const parsedPermissionData = JSON.parse(permissionData);
            this.permissions[socialNetwork] = parsedPermissionData;
            return parsedPermissionData;
        }
        return null;
    },
    removePermissionFromStorage: (socialNetwork: string) => {
        const permissionKey = `add-permission-${socialNetwork}`;
        localStorage.removeItem(permissionKey);
    },
}));
